@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

svg {
  font-family: "Russo One", sans-serif;
  width: 100%;
  height: 100%;
}
svg text {
  animation: stroke 9s infinite alternate;
  stroke-width: 100;
  stroke: #ffffff;
  font-size: 70px;
}
@keyframes stroke {
  0% {
    fill: rgba(246, 243, 243, 0);
    stroke: rgba(255, 255, 255, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  100% {
    fill: rgba(255, 255, 255, 0);
    stroke: rgba(255, 255, 255, 1);
  }
  100% {
    fill: rgba(255, 255, 255, 0);
    stroke: rgba(255, 255, 255, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(255, 255, 255, 1);
    stroke: rgba(255, 255, 255, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
